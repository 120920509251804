import { operate } from '../util/lift';
import { createOperatorSubscriber } from './OperatorSubscriber';
export function every(predicate, thisArg) {
  return operate((source, subscriber) => {
    let index = 0;
    source.subscribe(createOperatorSubscriber(subscriber, value => {
      if (!predicate.call(thisArg, value, index++, source)) {
        subscriber.next(false);
        subscriber.complete();
      }
    }, () => {
      subscriber.next(true);
      subscriber.complete();
    }));
  });
}
